import { useState, useEffect } from "react"
import Header from "./header";
import Footer from "./footer";

const Search = ({ user, signIn, selectedLanguage }) => {
    const englishQuote = "It's beautiful that AI can make every question, every doubt, of every human being answerable quickly. The challenge remains that the answers must be based on evidence and data, that's why I love working at VortiX. Ideally, this work contributes to the advancement of science globally.";

    return (
        <>
            <Header signIn={signIn} user={user} selectedLanguage={selectedLanguage} />
            <div className="flex grid grid-cols-1 py-36">
                <div className="px-4 sm:px-8 lg:px-24 2xl:px-44">
                    <div>
                        <p className="text-indigo-600 font-semibold text-2xl sm:text-3xl pt-5">
                            {selectedLanguage === "es-ES" ? "Misión" : "Mission"}
                        </p>
                    </div>
                    <div className="pt-8 sm:pt-10 pb-24 sm:pb-32">
                        <p className="text-gray-500 text-justify pt-3">
                            {selectedLanguage === "es-ES"
                                ? "Es hermoso que la IA haga que cada pregunta, cada duda, de cada ser humano pueda ser respondida rápidamente, el reto sigue siendo que las respuestas estén basadas en evidencia, en datos, por eso me encanta trabajar en VortiX. Idealmente, este trabajo contribuye al avance de la ciencia a nivel global."
                                : englishQuote
                            }
                        </p>
                        <a
                            href="https://x.com/HctorAlber2763"
                            target="_blank"
                            rel="noopener noreferrer"
                            className="inline-block mt-4 text-indigo-600 hover:text-indigo-800"
                        >
                            Hector Diaz
                        </a>
                    </div>
                </div>
            </div>
            <Footer selectedLanguage={selectedLanguage} />
        </>
    )
}

export default Search;