import React, { useState, useEffect } from "react";
import { SearchIcon, SparklesIcon } from '@heroicons/react/solid';
import { CreditCard, FileText, LogIn, LogOut } from 'lucide-react';
import { BeakerIcon, LightBulbIcon, DocumentSearchIcon, ChartBarIcon } from '@heroicons/react/outline';
import { Link } from "react-router-dom";
import FAQComponent from "./components/faq";
import Footer from "./components/footer";
import UserInfoPopup from "./components/userinfo";
import ChatPdf from "./assets/chat_with_paper.png"
import SolutionEngineFeatures from "./components/card";
import AuthPopup from "./components/authPopup"
 
const Home = ({
    searchTerm,   
    handleSearch,
    signInWithEmail,
    signInTres,
    handleLogout,
    setSearchTerm,
    signIn, 
    user,
    deepSearch,
    setDeepSearch,
    selectedLanguage,
    subscriptionStatus
}) => {
    const [showPopup, setShowPopup] = useState(false);
    const [showAuthPopup, setShowAuthPopup] = useState(false);

    const handleUnauthenticatedAction = (e) => {
        e.preventDefault();
        setShowAuthPopup(true);
    };

    const searchQuestions = [
        {
            es: "¿Cómo se origina la conciencia en el cerebro?",
            en: "How does consciousness arise in the brain?",
            icon: <LightBulbIcon className="w-5 h-5" />
        },
        {
            es: "¿Cuál es el origen de la vida en la tierra?",
            en: "What is the origin of life on Earth?",
            icon: <DocumentSearchIcon className="w-5 h-5" />
        },
        {
            es: "¿Cómo funciona la evolución?",
            en: "How does evolution work?",
            icon: <ChartBarIcon className="w-5 h-5" />
        }
    ];

    const handleExampleClick = (question) => (e) => {
        e.preventDefault();
        if (!user) {
            setShowAuthPopup(true);
            return;
        }
        const newSearchTerm = selectedLanguage === "es-ES" ? question.es : question.en;
        setSearchTerm(newSearchTerm);
        handleSearch(e, newSearchTerm);
    };

    return (
        <div className="min-h-screen bg-gradient-to-b from-white to-gray-50">
            {showPopup && (
                <UserInfoPopup
                    signIn={signIn}
                    user={user}
                    selectedLanguage={selectedLanguage}
                    onClose={() => setShowPopup(false)}
                />
            )}

            <header className="py-6 px-4 sm:px-6 lg:px-8 border-b border-gray-100">
                <div className="max-w-7xl mx-auto flex justify-between items-center">
                    <div className="flex items-center space-x-3">
                        <h1 className="text-xl font-bold text-gray-800">VortiX</h1>
                        <div className="flex items-center bg-indigo-50 rounded-full px-3 py-1">
                            <BeakerIcon className="w-4 h-4 text-indigo-600 mr-1" />
                            <span className="text-xs font-medium text-indigo-600">Beta</span>
                        </div>
                    </div>

                    {/* Mobile navigation */}
                    <nav className="sm:hidden flex items-center space-x-4">
                        <Link to="/billing"
                            className="text-yellow-500 p-2">
                            <CreditCard className="w-5 h-5" />
                        </Link>
                        {user && (
                            <Link to="/saved"
                                className="text-gray-700 p-2">
                                <FileText className="w-5 h-5" />
                            </Link>
                        )}
                        {user ? (
                            <button onClick={handleLogout}
                                className="text-gray-700 p-2">
                                <LogOut className="w-5 h-5" />
                            </button>
                        ) : (
                            <button onClick={() => setShowAuthPopup(true)}
                                className="text-gray-700 p-2">
                                <LogIn className="w-5 h-5" />
                            </button>
                        )}
                    </nav>

                    {/* Desktop navigation */}
                    <nav className="hidden sm:flex items-center space-x-6">
                        <Link to="/billing"
                            className="bg-white text-gray-700 px-4 py-2 rounded-lg text-sm font-medium transition-colors flex items-center gap-2">
                            <CreditCard className="w-4 h-4" />
                            {selectedLanguage === "es-ES" ? "Pagos" : "Pricing"}
                        </Link>
                        {user && (
                            <Link to="/saved"
                                className="bg-white text-gray-700 px-4 py-2 rounded-lg text-sm font-medium transition-colors flex items-center gap-2">
                                <FileText className="w-4 h-4" />
                                {selectedLanguage === "es-ES" ? "Guardados" : "Saved"}
                            </Link>
                        )}
                        {user ? (
                            <button onClick={handleLogout}
                                className="bg-white border border-gray-200 text-gray-700 hover:bg-gray-50 px-4 py-2 rounded-lg text-sm font-medium transition-colors">
                                {selectedLanguage === "es-ES" ? "Salir" : "Log out"}
                            </button>
                        ) : (
                            <button onClick={() => setShowAuthPopup(true)}
                                className="bg-indigo-600 text-white hover:bg-indigo-700 px-4 py-2 rounded-lg text-sm font-medium transition-colors">
                                {selectedLanguage === "es-ES" ? "Acceder" : "Sign in"}
                            </button>
                        )}
                    </nav>
                </div>
            </header>

            <main className="max-w-6xl mx-auto px-4 py-16 sm:py-24">
                <div className="text-center space-y-8">
                    <div className="space-y-4">
                        <h1 className="text-4xl sm:text-6xl font-bold text-gray-900">
                            {selectedLanguage === "es-ES" ? "Motor de búsqueda con " : "AI Search Engine For "}
                            <span className="text-transparent bg-clip-text bg-gradient-to-r from-indigo-600 to-purple-600">
                                {selectedLanguage === "es-ES" ? "IA" : "Research"}
                            </span>
                        </h1>
                        <p className="text-xl text-gray-600 max-w-2xl mx-auto">
                            {selectedLanguage === "es-ES"
                                ? "Respuestas basadas en investigación científica y datos verificados"
                                : "Get reliable answers backed by scientific research and verified data"}
                        </p>
                    </div>

                    {/* Search Bar */}
                    <div className="max-w-3xl mx-auto">
                        <form onSubmit={user ? handleSearch : handleUnauthenticatedAction} className="space-y-3">
                            <div className="flex items-center bg-white rounded-xl border border-gray-200 shadow-sm hover:border-gray-300 transition-colors">
                                <input
                                    type="text"  
                                    value={searchTerm}
                                    onChange={(e) => setSearchTerm(e.target.value)}
                                    placeholder={selectedLanguage === "es-ES"
                                        ? "Haz una pregunta científica..."
                                        : "Ask a research question..."}
                                    className="w-full px-6 py-4 rounded-xl text-gray-700 focus:outline-none"
                                />
                                <button
                                    type="submit"
                                    onClick={(e) => user? handleSearch(e, searchTerm) : signInTres}
                                    className="px-6 py-4 text-gray-400 hover:text-gray-600 transition-colors"
                                >
                                    <SearchIcon className="w-5 h-5" />
                                </button>
                            </div>

                            <div className="flex items-center justify-center space-x-2">
                                <div className="flex items-center">
                                    <label className="relative inline-flex items-center cursor-pointer">
                                        <input
                                            type="checkbox"
                                            className="sr-only peer"
                                            checked={deepSearch}
                                            onChange={(e) => setDeepSearch(e.target.checked)}
                                        />
                                        <div className="w-11 h-6 bg-gray-200 peer-focus:outline-none peer-focus:ring-4 peer-focus:ring-indigo-100 rounded-full peer peer-checked:after:translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:left-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all peer-checked:bg-indigo-600"></div>
                                    </label>
                                    <span className="ml-3 text-sm font-medium text-gray-700">
                                        {selectedLanguage === "es-ES" ? "Búsqueda profunda" : "Deep Search"}
                                    </span>
                                    <span className="ml-1 group relative">
                                        <SparklesIcon className="w-4 h-4 text-indigo-600 cursor-help" />
                                        <span className="absolute bottom-full left-1/2 -translate-x-1/2 mb-2 w-48 p-2 bg-gray-800 text-white text-xs rounded-lg opacity-0 group-hover:opacity-100 transition-opacity">
                                            {selectedLanguage === "es-ES"
                                                ? "Búsqueda en más de 30 artículos académicos"
                                                : "Search across 30+ academic papers"}
                                        </span>
                                    </span>
                                </div>
                            </div>
                        </form>
                    </div>

                    <div className="space-y-4">
                        <p className="text-sm text-gray-500">
                            {selectedLanguage === "es-ES"
                                ? "Prueba con estos ejemplos:"
                                : "Try these examples:"}
                        </p>
                        <div className="flex flex-wrap justify-center gap-3">
                            {searchQuestions.map((question, index) => (
                                <button
                                    key={index}
                                    onClick={handleExampleClick(question)}
                                    className="flex items-center space-x-2 px-4 py-2 bg-white border border-gray-200 rounded-lg text-sm text-gray-600 hover:bg-gray-50 hover:border-gray-300 transition-all"
                                >
                                    {question.icon}
                                    <span>{selectedLanguage === "es-ES" ? question.es : question.en}</span>
                                </button>
                            ))}
                        </div>
                    </div>
                </div>
            </main>

            <div className="max-w-7xl mx-auto px-4 py-24 grid grid-cols-1 lg:grid-cols-2 gap-16 items-center">
                <div className="rounded-2xl overflow-hidden shadow-lg">
                    <img
                        src="https://maresone.s3.us-east-1.amazonaws.com/vortix_21.png"
                        alt="VortiX Demo"
                        className="w-full h-auto"
                    />
                </div>
                <div className="space-y-6">
                    <div className="inline-flex items-center space-x-2 px-3 py-1 bg-indigo-50 rounded-full">
                        <SparklesIcon className="w-4 h-4 text-indigo-600" />
                        <span className="text-sm font-medium text-indigo-600">
                            {selectedLanguage === "es-ES" ? "Análisis generado por IA" : "AI-generated analysis"}
                        </span>
                    </div>
                    <h2 className="text-3xl font-bold text-gray-900">
                        {selectedLanguage === "es-ES"
                            ? "Resultados confiables de Semantic Scholar"
                            : "Reliable results from Semantic Scholar"}
                    </h2>
                    <p className="text-lg text-gray-600">
                        {selectedLanguage === "es-ES"
                            ? "Accede a más de 220 millones de papers académicos para resolver tus dudas con información verificada."
                            : "Access over 220 million academic papers to get verified answers to your research questions."}
                    </p>
                </div>
            </div> 
            <div className="max-w-7xl mx-auto px-4 py-24 grid grid-cols-1 lg:grid-cols-2 gap-16 items-center">
                <div className="space-y-6">
                    <div className="inline-flex items-center space-x-2 px-3 py-1 bg-indigo-50 rounded-full">
                        <SparklesIcon className="w-4 h-4 text-indigo-600" />
                        <span className="text-sm font-medium text-indigo-600">
                            {selectedLanguage === "es-ES" ? "Chatea con papers" : "Chat with Papers"}
                        </span>
                    </div>
                    <h2 className="text-3xl font-bold text-gray-900">
                        {selectedLanguage === "es-ES"
                            ? "Interactúa directamente con artículos científicos"
                            : "Interact directly with scientific papers"}
                    </h2>
                    <p className="text-lg text-gray-600">
                        {selectedLanguage === "es-ES"
                            ? "Haz preguntas específicas sobre cualquier artículo académico y obtén respuestas instantáneas basadas en su contenido. Analiza, comprende y extrae información clave de manera eficiente."
                            : "Ask specific questions about any academic paper and get instant answers based on its content. Analyze, understand, and extract key information efficiently."}
                    </p>
                </div>
                <div className="rounded-2xl overflow-hidden shadow-lg">
                    <img
                        src={ChatPdf}
                        alt="VortiX Demo"
                        className="w-full h-auto"
                    />
                </div>
            </div> 

            <SolutionEngineFeatures selectedLanguage={selectedLanguage} />
            <FAQComponent selectedLanguage={selectedLanguage} /> 
            <Footer user={user} />
            {!user && <AuthPopup
                isOpen={showAuthPopup}
                onClose={() => setShowAuthPopup(false)}
                onGoogleSignIn={signInTres}
                onEmailSignIn={signInWithEmail}
                selectedLanguage={selectedLanguage}
            />}
        </div>
    );
};

export default Home;