import React from "react";

const MessageContent = ({ text }) => {
    const processInlineFormatting = (content) => {
        let processedContent = content;

        processedContent = processedContent.replace(
            /\*\*\*(.*?)\*\*\*/g,
            (match, text) => `<span class="font-semibold italic text-gray-900">${text}</span>`
        );

        processedContent = processedContent.replace(
            /\*\*(.*?)\*\*/g,
            (match, text) => `<span class="font-semibold text-gray-900">${text}</span>`
        );

        processedContent = processedContent.replace(
            /(?<!\*)\*(?!\*)(.*?)(?<!\*)\*(?!\*)/g,
            (match, text) => `<span class="italic text-gray-800">${text}</span>`
        );

        processedContent = processedContent.replace(
            /`(.*?)`/g,
            (match, text) => `<code class="bg-gray-100 rounded px-1.5 py-0.5 text-sm text-gray-800 font-mono">${text}</code>`
        );

        return processedContent;
    };

    const renderText = (content) => {
        const lines = content.split('\n');
        let formattedContent = [];
        let inCodeBlock = false;
        let codeContent = [];
        let inList = false;
        let listItems = [];

        const processLine = (line, index) => {
            if (line.startsWith('```')) {
                if (inCodeBlock) {
                    formattedContent.push(
                        <div key={`code-${index}`} className="my-4 bg-gray-800 rounded-lg p-4 font-mono text-sm text-gray-200 overflow-x-auto whitespace-pre">
                            {codeContent.join('\n')}
                        </div>
                    );
                    codeContent = [];
                    inCodeBlock = false;
                } else {
                    inCodeBlock = true;
                }
                return;
            }

            if (inCodeBlock) {
                codeContent.push(line);
                return;
            }

            if (line.startsWith('# ')) {
                formattedContent.push(
                    <h1 key={index} className="text-3xl font-bold text-gray-900 mt-6 mb-4">
                        {processInlineFormatting(line.slice(2))}
                    </h1>
                );
                return;
            }
            if (line.startsWith('## ')) {
                formattedContent.push(
                    <h2 key={index} className="text-2xl font-bold text-gray-900 mt-5 mb-3">
                        {processInlineFormatting(line.slice(3))}
                    </h2>
                );
                return;
            }
            if (line.startsWith('### ')) {
                formattedContent.push(
                    <h3 key={index} className="text-xl font-bold text-gray-900 mt-4 mb-2">
                        {processInlineFormatting(line.slice(4))}
                    </h3>
                );
                return;
            }

            const isListItem = line.match(/^([0-9]+\.|[*-]) /);
            if (isListItem) {
                const itemContent = line.replace(/^([0-9]+\.|[*-]) /, '');
                const isOrdered = /^[0-9]+\./.test(line);

                if (!inList) {
                    if (listItems.length > 0) {
                        const ListComponent = isOrdered ? 'ol' : 'ul';
                        formattedContent.push(
                            <ListComponent key={`list-${index}`} className={`my-2 space-y-1 ${isOrdered ? 'list-decimal' : 'list-disc'} pl-6`}>
                                {listItems.map((item, i) => (
                                    <li key={i} className="text-gray-700" dangerouslySetInnerHTML={{ __html: processInlineFormatting(item) }} />
                                ))}
                            </ListComponent>
                        );
                        listItems = [];
                    }
                    inList = true;
                }
                listItems.push(itemContent);
                return;
            } else if (inList && listItems.length > 0) {
                const ListComponent = /^[0-9]+\./.test(listItems[0]) ? 'ol' : 'ul';
                formattedContent.push(
                    <ListComponent key={`list-${index}`} className={`my-2 space-y-1 ${/^[0-9]+\./.test(listItems[0]) ? 'list-decimal' : 'list-disc'} pl-6`}>
                        {listItems.map((item, i) => (
                            <li key={i} className="text-gray-700" dangerouslySetInnerHTML={{ __html: processInlineFormatting(item) }} />
                        ))}
                    </ListComponent>
                );
                listItems = [];
                inList = false;
            }

            if (!line.trim()) {
                formattedContent.push(<div key={index} className="h-4" />);
                return;
            }

            formattedContent.push(
                <p
                    key={index}
                    className="text-gray-700 leading-relaxed my-2"
                    dangerouslySetInnerHTML={{ __html: processInlineFormatting(line) }}
                />
            );
        };

        lines.forEach((line, index) => processLine(line, index));

        if (listItems.length > 0) {
            const ListComponent = /^[0-9]+\./.test(listItems[0]) ? 'ol' : 'ul';
            formattedContent.push(
                <ListComponent key="list-final" className={`my-2 space-y-1 ${/^[0-9]+\./.test(listItems[0]) ? 'list-decimal' : 'list-disc'} pl-6`}>
                    {listItems.map((item, i) => (
                        <li key={i} className="text-gray-700" dangerouslySetInnerHTML={{ __html: processInlineFormatting(item) }} />
                    ))}
                </ListComponent>
            );
        }

        return formattedContent;
    };

    try {
        const parsedJson = JSON.parse(text);
        if (parsedJson.message) {
            return (
                <div className="space-y-2">
                    {renderText(parsedJson.message)}
                </div>
            );
        }
    } catch (e) {
        return (
            <div className="space-y-2">
                {renderText(text)}
            </div>
        );
    }
};

export default MessageContent;