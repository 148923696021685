import React from 'react';
import { ArrowRight } from 'lucide-react';

const SampleQuestions = ({ setQuestionx, formRef, selectedLanguage, isVisible, setVisible }) => {
    const questions = [
        {
            en: "Summarize the paper in a few sentences",
            es: "Resume el artículo en pocas frases"
        },
        {
            en: "Were there any conflicts of interest?",
            es: "¿Hubo algún conflicto de intereses?"
        },
        {
            en: "Did the authors mention any limitations?",
            es: "¿Los autores mencionaron alguna limitación?"
        }
    ];

    const handleQuestionClick = (question) => {
        const questionText = selectedLanguage === "es-ES" ? question.es : question.en;
        setQuestionx(questionText);
        setVisible(false);
        setTimeout(() => {
            formRef.current?.dispatchEvent(
                new Event('submit', { cancelable: true, bubbles: true })
            );
        }, 0);
    };

    if (!isVisible) return null;

    return (
        <div className="flex flex-col gap-2 p-4">
            {questions.map((question, index) => (
                <button
                    key={index}
                    onClick={() => handleQuestionClick(question)}
                    className="flex items-center justify-between bg-blue-50 hover:bg-blue-100 text-blue-700 p-4 rounded-lg text-left transition-colors w-full"
                >
                    <span>{selectedLanguage === "es-ES" ? question.es : question.en}</span>
                    <ArrowRight className="w-5 h-5" />
                </button>
            ))}
        </div>
    );
};

export default SampleQuestions;