import React, { useState, useEffect, useRef } from 'react';
import { ListFilter, ArrowLeft, SparkleIcon, Send, X, Loader, CreditCard } from 'lucide-react';
import { LogoutIcon } from "@heroicons/react/outline";
import List from './components/list';
import SampleQuestions from "./components/sampleQuestions"
import { BeakerIcon } from '@heroicons/react/solid';
import { SparklesIcon } from '@heroicons/react/solid';
import checkgreenOne from "./assets/icons/checkGreenOne.png"
import { HiMiniArrowTopRightOnSquare } from "react-icons/hi2";
import axios from "axios";
import UserInfoPopup from "./components/userinfo"
import Header from "./components/header"
import Footer from './components/footer';
import ScrollToTop from "./scroll"
import { RiFilePdf2Fill } from "react-icons/ri"
import { IoArrowBackCircleOutline } from 'react-icons/io5';
import { PiBookBookmarkBold, PiBookmarksSimpleFill, PiCheckCircleBold, PiCopyBold, PiQuotesBold } from 'react-icons/pi';
import { FaSearch } from 'react-icons/fa';
import { auth } from "./firebase";
import { collection, addDoc, serverTimestamp } from 'firebase/firestore';
import { db } from "./firebase"
import Sidebar from './components/sidebar';
import { useNavigate, Link } from "react-router-dom";
import { XCircleIcon, UserCircleIcon, XIcon } from "@heroicons/react/solid";
import Home from './home';
import Result from "./components/results"
import MessageContent from "./components/message"

const Papers = ({ subscriptionStatus, handleSelectLanguage, signInWithEmail, signInDos, signInTres, setSavedStates, savedStates, selectedLanguage, savedDocs, setSelectedLanguage, showModal, setShowModal, user, userName, userAge, signIn }) => {
    const [questionx, setQuestionx] = useState("");
    const [isQuestionsVisible, setIsQuestionsVisible] = useState(true);
    const formRef = useRef(null);
    const scrollToTop = () => {
        window.scrollTo({
            top: 0,
            left: 0,
            behavior: 'smooth',
        });
    };

    const [firstSearch, setFirstSearch] = useState(false)
    const [sampleQuestionsVisible, setSampleQuestionsVisible] = useState(true);
    const [chatDoc, setChatDoc] = useState(false)
    const [followup, setFollowup] = useState(false)
    const [selectedDocument, setSelectedDocument] = useState(null);
    const [selectedPaper, setSelectedPaper] = useState(null)
    const [showDetails, setShowDetails] = useState(false);
    const [mainWebsite, setMainWebsite] = useState(true)
    const [comparePapers, setComparePapers] = useState(false)
    const [errorMessage, setErrorMessage] = useState("");
    const [deepSearch, setDeepSearch] = useState(false);
    const [indChat, setIndChat] = useState(true)

    const handleDocumentSelection = (paper) => {
        scrollToTop()
        setSelectedDocument(paper);
        setShowDetails(true);
        setChatDoc(false)
        setComparePapers(false)
        setMainWebsite(false)
        setFollowup(false)
    };

    const [savePaper, setSavePaper] = useState(false)

    const closeDetails = () => {
        setShowDetails(false);
        setMainWebsite(true)
        setFollowup(false)
        setChatDoc(false)
        setComparePapers(false)
        setSavePaper(false)
    };

    const handlePaperSelection = (e, paper) => {
        scrollToTop()
        setSelectedPaper(paper)
        setChatDoc(true)
        setComparePapers(false)
        setShowDetails(false)
        setFollowup(false)
        setMainWebsite(false)
        downloadPDF(e, paper)
    }

    const [searchTerm, setSearchTerm] = useState('')
    const [loading, setLoading] = useState(false);
    const [recommendations, setRecommendations] = useState([]);
    const [isOpen, setIsOpen] = useState(false);

    const [trOpen, setTrOpen] = useState(false)

    const clear = () => {
        setSearchTerm("")
    }


    const handleLogout = () => {
        auth.signOut();
    };

    const [fieldsOfStudy, setFieldsOfStudy] = useState([]);
    const [publicationTypes, setPublicationTypes] = useState([]);
    const handleCheckboxChange = (event, type) => {
        const { checked, value } = event.target;
        if (type === 'field') {
            setFieldsOfStudy(prev =>
                checked ? [...prev, value] : prev.filter(item => item !== value)
            );
        } else if (type === 'publication') {
            setPublicationTypes(prev =>
                checked ? [...prev, value] : prev.filter(item => item !== value)
            );
        }
    };

    const Navigate = useNavigate()

    const [enabled, setEnabled] = useState(() => {
        const saved = localStorage.getItem('enabledState');
        return saved === null ? true : saved === 'true';
    });

    useEffect(() => {
        localStorage.setItem('enabledState', enabled);
    }, [enabled]);


    const [free, setFree] = useState("")

    useEffect(() => {
    }, [fieldsOfStudy]);

    useEffect(() => {
    }, [publicationTypes]);

    const [year, setYear] = useState("")
    const [yearRange, setYearRange] = useState("")
    const [yearBefore, setYearBefore] = useState("")
    const [yearAfter, setYearAfter] = useState("")
    const [context, setContext] = useState("")
    const [webResults, setWebResults] = useState(false);


    const [data, setData] = useState({
        summary: '',
        yes: '',
        maybe: '',
        no: '',
        answer: "",
        responses: []
    });

    const [summaryx, setSummaryX] = useState("")

    const handleSearch = async (e, overrideSearchTerm = null) => {
        if (e) e.preventDefault();

        const searchTermx = overrideSearchTerm || searchTerm;
        setLoading(true);
        setFirstSearch(true);
        setSummaryX("");
        setWebResults(false);

        const filterParams = new URLSearchParams();

        filterParams.append('query', searchTermx);
        filterParams.append('selectedLanguage', selectedLanguage);
        filterParams.append('stream', 'true');
        filterParams.append('subscriptionStatus', subscriptionStatus);
        filterParams.append('deepSearch', deepSearch);
        filterParams.append('userUID', user?.uid || `anonymous-${Date.now()}-${Math.random().toString(36).substr(2, 9)}`);

        if (year) filterParams.append('year', year);
        if (yearRange) filterParams.append('yearRange', yearRange);
        if (yearBefore) filterParams.append('yearBefore', yearBefore);
        if (yearAfter) filterParams.append('yearAfter', yearAfter);

        if (free === "isOpenAccess") filterParams.append('isOpenAccess', 'true');

        if (fieldsOfStudy.length > 0) {
            filterParams.append('fieldsOfStudy', JSON.stringify(fieldsOfStudy));
        }

        if (publicationTypes.length > 0) {
            filterParams.append('publicationTypes', JSON.stringify(publicationTypes));
        }

        try {
            const response = await fetch(
                `https://vortixlol.gaia-ml.com/api/search-one/?${filterParams.toString()}`
            );

            if (!response.ok) {
                throw new Error('Failed to fetch search results');
            }

            const reader = response.body.getReader();
            const decoder = new TextDecoder();
            let buffer = '';

            while (true) {
                const { value, done } = await reader.read();
                if (done) break;

                buffer += decoder.decode(value, { stream: true });
                const lines = buffer.split('\n');
                for (let i = 0; i < lines.length - 1; i++) {
                    const line = lines[i].trim();
                    if (line.startsWith('data: ')) {
                        try {
                            const data = JSON.parse(line.slice(6));

                            if (data.type === 'search_results') {
                                setRecommendations(data?.data);
                                setContext(data.data.slice(0, 1));
                                const hasWebResults = data.data.some(paper =>
                                    paper.venue?.toLowerCase().includes('web') ||
                                    paper.url?.includes('http')
                                );
                                setWebResults(hasWebResults);
                                setLoading(false);
                            } else if (data.type === 'ai_response') {
                                setSummaryX(prev => prev + data.data);
                            }
                        } catch (e) {
                            console.error('Error parsing JSON:', e);
                        }
                    }
                }
                buffer = lines[lines.length - 1];
            }

        } catch (error) {
            console.error('Error fetching search data:', error);
            setSummaryX(selectedLanguage === 'es-ES' ?
                "Se produjo un error al obtener los resultados." :
                "An error occurred while fetching the results."
            );
            setLoading(false);
        }
    };

    const trBar = useRef()

    const handleClicko = (event) => {
        if (trBar.current && !trBar.current.contains(event.target)) {
            setTrOpen(false);
        }
    };

    useEffect(() => {
        document.addEventListener('mousedown', handleClicko);
        return () => {
            document.removeEventListener('mousedown', handleClicko);
        };
    }, []);


    const sidebarRef = useRef();

    const handleClickOutside = (event) => {
        if (sidebarRef.current && !sidebarRef.current.contains(event.target)) {
            setIsOpen(false);
        }
    };

    useEffect(() => {
        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);

    const [messages, setMessages] = useState([]);

    const [getPDF, setGetPDF] = useState(false)

    const [displayError, setDisplayError] = useState(false)
    const [indexing, setIndexing] = useState(false);
    const [ready, setReady] = useState(false)

    const downloadPDF = async (e, paper) => {
        setGetPDF(true);
        setIndexing(true);
        setDisplayError(false);
        setErrorMessage("");

        if (e) e.preventDefault();
        const url = paper?.open_access?.url;
        const uid = user?.uid || 'anonymous';
        const folderName = user?.email || 'anonymous';

        try {
            const response = await axios.post("https://vortixlol.gaia-ml.com/api/download/", {
                url: url,
                folder_name: folderName,
                user_uid: uid,
                selectedLanguage: selectedLanguage,
                paper_title: paper?.title || '',
                paper_authors: paper?.authors || []
            });

            if (response.data.success) {
                setReady(true);
            } else {
                setDisplayError(true);
                if (response.data.message) {
                    setErrorMessage(response.data.message);
                }
            }
        } catch (err) {
            setDisplayError(true);
            setErrorMessage("");
            console.error("Error downloading PDF:", err);
        } finally {
            setGetPDF(false);
            setTimeout(() => {
                setIndexing(false);
            }, 30000);
        }
    };


    const [loadRequest, setLoadRequest] = useState(false)

    const [saved, setSaved] = useState(false)

    const saveRef = collection(db, "s3");

    const saveToFirebase = async (paper) => {
        if (!user || !user.email) {
            signIn();
            return;
        }

        try {
            await addDoc(saveRef, {
                ...paper,
                id: paper.url.split('/').pop(),
                uid: user.uid,
                timestamp: serverTimestamp()
            });
            setSavedStates(prev => ({
                ...prev,
                [paper.url]: true
            }));
        } catch (error) {
            alert(error);
        }
    };

    const handleSaving = (paper) => {
        saveToFirebase(paper);
    };

    const [showCopiedMessage, setShowCopiedMessage] = useState(false);
    const [citationPopup, setCitationPopup] = useState({ show: false, citation: '' });

    const formatCitationAPA = (selectedDocument) => {
        const authors = selectedDocument?.author?.name || selectedDocument?.author || "";
        const year = selectedDocument?.year || "";
        const title = selectedDocument?.title || "";
        const journal = selectedDocument?.journal?.name || "";
        const volume = selectedDocument?.volume || "";
        const pages = selectedDocument?.pages || "";
        const url = selectedDocument?.openAccessPdf?.url || selectedDocument?.url;
        return `${authors} (${year}). ${title}. <i>${journal}</i>, ${volume}, ${pages} <br/> <p style="color: #2563eb;">${url}</p>`;
    };

    const showCitationPopup = (selectedDocument) => {
        const citation = formatCitationAPA(selectedDocument);
        setCitationPopup({ show: true, citation });
    };

    const closeCitationPopup = () => {
        setCitationPopup({ show: false, citation: '' });
    };

    const copyToClipboard = (text) => {
        navigator.clipboard.writeText(text);
        setShowCopiedMessage(true);
        setTimeout(() => {
            setShowCopiedMessage(false);
        }, 1500);
    };

    const sendRequest = async (e, selectedPaper) => {
        e.preventDefault();
        setLoadRequest(true);

        setMessages(messages => [
            ...messages,
            { type: 'user', text: questionx },
            { type: 'api', text: '' }
        ]);

        try {
            const response = await fetch("https://vortixlol.gaia-ml.com/api/chat/", {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    message: questionx,
                    paperUrl: selectedPaper?.open_access?.url,
                    docId: selectedPaper?.doc_id,
                    selectedLanguage: selectedLanguage
                }),
            });

            if (!response.ok) {
                throw new Error(`HTTP error! status: ${response.status}`);
            }

            const reader = response.body.getReader();
            const decoder = new TextDecoder();
            let buffer = '';

            while (true) {
                const { value, done } = await reader.read();
                if (done) break;

                buffer += decoder.decode(value, { stream: true });
                const lines = buffer.split('\n');

                for (let i = 0; i < lines.length - 1; i++) {
                    const line = lines[i].trim();
                    if (line.startsWith('data: ')) {
                        try {
                            const data = JSON.parse(line.slice(6));

                            if (data.type === 'chunk') {
                                setMessages(messages => {
                                    const newMessages = [...messages];
                                    const lastMessage = newMessages[newMessages.length - 1];
                                    if (lastMessage.type === 'api') {
                                        lastMessage.text += data.content;
                                    }
                                    return newMessages;
                                });
                            } else if (data.type === 'error') {
                                throw new Error(data.content);
                            }
                        } catch (e) {
                            console.error('Error parsing JSON:', e);
                        }
                    }
                }
                buffer = lines[lines.length - 1];
            }

        } catch (error) {
            console.error("Chat error:", error);
            setMessages(messages => {
                const newMessages = [...messages];
                const lastMessage = newMessages[newMessages.length - 1];
                if (lastMessage.type === 'api') {
                    lastMessage.text = selectedLanguage === 'es-ES'
                        ? 'Se produjo un error al procesar tu solicitud.'
                        : 'An error occurred while processing your request.';
                }
                return newMessages;
            });
        } finally {
            setLoadRequest(false);
            setQuestionx("");
        }
    };

    const [showPopup, setShowPopup] = useState(false);
    const [userInfo, setUserInfo] = useState(null);

    const closePopup = () => {
        setShowPopup(false);
    };
    const handleUserInfoSubmit = (info) => {
        setUserInfo(info);
    };

    const clearAll = () => {
        setFirstSearch(false)
        setSearchTerm("")
    }

    const closeChat = () => {
        setIndChat(true)
        setComparePapers(false)
        setChatDoc(false)
        setShowDetails(false)
        setComparePapers(false)
        setMainWebsite(true)
        setMessages([])
        setGetPDF(false)
        setReady(false)
        setDisplayError(false)
    }

    const citationRefs = useRef([]);

    const scrollToReference = (index) => {
        citationRefs.current[index]?.scrollIntoView({
            behavior: 'smooth',
            block: 'center'
        });
    };

    const formatTextWithCitations = (text) => {
        const parts = text.split(/(\[\d+\])/g);
        return parts.map((part, index) => {
            const match = part.match(/\[(\d+)\]/);
            if (match) {
                const citationNumber = parseInt(match[1]);
                return (
                    <button
                        key={index}
                        onClick={() => scrollToReference(citationNumber - 1)}
                        className="inline-flex items-center justify-center w-6 h-6 text-sm font-medium bg-indigo-100 text-indigo-800 rounded-full hover:bg-indigo-200 transition-colors mx-1"
                    >
                        {citationNumber}
                    </button>
                );
            }

            let processedText = part;
            processedText = processedText.split(/((https?:\/\/[^\s]+))/g).map((segment, urlIndex) => {
                if (segment.match(/^https?:\/\/[^\s]+$/)) {
                    return (
                        <a
                            key={`${index}-url-${urlIndex}`}
                            href={segment}
                            target="_blank"
                            rel="noopener noreferrer"
                            className="text-blue-600 hover:text-blue-800 hover:underline"
                        >
                            {segment}
                        </a>
                    );
                }
                return segment;
            });

            // Bold
            processedText = processedText.map(segment => {
                if (typeof segment !== 'string') return segment;
                return segment.split(/(\*\*.*?\*\*)/g).map((boldSegment, segIndex) => {
                    const boldMatch = boldSegment.match(/^\*\*(.*?)\*\*$/);
                    if (boldMatch) {
                        return <strong key={`${index}-bold-${segIndex}`}>{boldMatch[1]}</strong>;
                    }
                    return boldSegment;
                });
            });

            // Italic
            processedText = processedText.map(segment => {
                if (typeof segment !== 'string') return segment;
                return segment.split(/(\*.*?\*)/g).map((italicSegment, italicIndex) => {
                    const italicMatch = italicSegment.match(/^\*(.*?)\*$/);
                    if (italicMatch) {
                        return <em key={`${index}-italic-${italicIndex}`}>{italicMatch[1]}</em>;
                    }
                    return italicSegment;
                });
            });

            // Inline code
            processedText = processedText.map(segment => {
                if (typeof segment !== 'string') return segment;
                return segment.split(/(`.*?`)/g).map((codeSegment, codeIndex) => {
                    const codeMatch = codeSegment.match(/^`(.*?)`$/);
                    if (codeMatch) {
                        return <code key={`${index}-code-${codeIndex}`} className="bg-gray-100 px-1 rounded">{codeMatch[1]}</code>;
                    }
                    return codeSegment;
                });
            });

            const flattenedText = processedText.flat(3).filter(item => item !== '');

            return <span key={index}>{flattenedText}</span>;
        });
    };

    return (
        <>
            {!firstSearch && <Home subscriptionStatus={subscriptionStatus} signInWithEmail={signInWithEmail} handleLogout={handleLogout} signInTres={signInTres} deepSearch={deepSearch} setDeepSearch={setDeepSearch} handleSearch={handleSearch} searchTerm={searchTerm} setSearchTerm={setSearchTerm} signIn={signIn} user={user} handleSelectLanguage={handleSelectLanguage} selectedLanguage={selectedLanguage} setSelectedLanguage={setSelectedLanguage} showModal={showModal} setShowModal={setShowModal} />}
            {showPopup && (
                <UserInfoPopup signIn={signIn} user={user} selectedLanguage={selectedLanguage} onClose={closePopup} onSubmit={handleUserInfoSubmit} />
            )}
            {firstSearch &&
                <>
                    <ScrollToTop />
                    {showDetails && selectedDocument && (
                        <>
                            {citationPopup.show && (
                                <div className="fixed inset-0 flex items-center justify-center z-50">
                                    <div className="modal-overlay absolute w-full h-full bg-gray-900 bg-opacity-50"></div>
                                    <div className="modal-container bg-white w-11/12 md:max-w-md mx-auto shadow-xl z-50">
                                        <div className="">
                                            <div className="w-full flex bg-gray-100">
                                                <div className="w-1/6"></div>
                                                <div className="w-4/6 flex justify-center py-3">
                                                    <p className="text-lg font-semibold text-gray-700">
                                                        {selectedLanguage === "es-ES" ? "Citar fuente" : "Cite source"}
                                                    </p>
                                                </div>
                                                <div className="w-1/6 flex items-center justify-center">
                                                    <XCircleIcon
                                                        onClick={closeCitationPopup}
                                                        className="text-gray-700 h-6 w-6 hover:text-gray-800 cursor-pointer"
                                                    />
                                                </div>
                                            </div>
                                            <div className="text-gray-600 rounded bg-gray-100 text-sm light p-6 relative">
                                                <div
                                                    className="citation-text font-sans break-words"
                                                    dangerouslySetInnerHTML={{
                                                        __html: citationPopup.citation
                                                    }}
                                                ></div>
                                                <PiCopyBold
                                                    className="my-2 w-5 h-5 cursor-pointer hover:text-gray-800 text-gray-600"
                                                    onClick={() =>
                                                        copyToClipboard(
                                                            citationPopup[`citation${citationPopup.selectedTab}`]
                                                        )
                                                    }
                                                    title="Copy to clipboard"
                                                />
                                                {showCopiedMessage && (
                                                    <div className="absolute bottom-2 border rounded-full left-1/2 transform -translate-x-1/2 bg-white border-green-500 text-green-500 px-5 py-2 rounded">
                                                        {selectedLanguage === "es-ES" ? "Texto copiado" : "Text copied"}
                                                    </div>
                                                )}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            )}
                            <Header />
                            <div className="w-full bg-gray-200 py-20 flex justify-center">
                                <div className='w-11/12 sm:w-3/4 p-4 sm:p-12 bg-white rounded-2xl'>
                                    <div className="sm:px-6 px-2 py-3">
                                        <IoArrowBackCircleOutline className='w-7 h-auto text-gray-600 hover:text-gray-800 cursor-pointer' onClick={closeDetails} />
                                    </div>
                                    <div className="p-2 sm:flex">
                                        <div className="w-full sm:w-1/3 sm:p-6">
                                            <div className="pb-6 border-b w-full border-gray-300">
                                                <a href={selectedDocument?.url} target='_blank' className="text-2xl cursor-pointer hover:underline font-medium pb-2">{selectedDocument.translatedTitle}</a>
                                                <div className="text-sm pt-2 flex">
                                                    <p className='text-gray-600'>{selectedDocument?.author} - {selectedDocument?.year}</p>
                                                </div>
                                                <div className="grid w-5/6 py-5 grid-cols-3">
                                                    <div className="flex justify-center">
                                                        <button href={selectedDocument?.url} target='_blank' rel="noopener noreferrer">
                                                            <RiFilePdf2Fill className="w-5 h-auto text-red-500 cursor-pointer hover:text-red-700" />
                                                        </button>

                                                    </div>
                                                    <div className="flex justify-center">
                                                         <PiQuotesBold
                                                            onClick={() => showCitationPopup(selectedDocument)}
                                                            className="w-5 h-auto text-gray-600 cursor-pointer hover:text-gray-800" />
                                                    </div>
                                                    {savePaper ?
                                                        <div className="flex justify-center">
                                                            <PiCheckCircleBold
                                                                className="h-5 w-auto text-green-500" />
                                                        </div> :
                                                        <div className="flex justify-center">
                                                            <button className='px-3 py-2 rounded-2xl bg-gray-200 flex items-center hover:bg-gray-100'>
                                                                <PiBookBookmarkBold
                                                                    onClick={() => saveToFirebase(selectedDocument)}
                                                                    className="w-5 h-auto text-gray-600 cursor-pointer hover:text-gray-800" />
                                                                <p className='text ml-2 text-gray-600 font-medium'>Save</p>
                                                            </button>
                                                        </div>
                                                    }
                                                </div>
                                            </div>
                                            <div className="w-full py-6">
                                                {selectedDocument?.journal?.name
                                                    &&
                                                    <div className="w-full py-3 px-1">
                                                        <div>
                                                            <p className="text-gray-500 font-medium"> {selectedLanguage === "es-ES" ? "Revista" : "Journal Name"} </p>
                                                        </div>
                                                        <div>
                                                            <p className='font-medium text-lg'>{selectedDocument?.journal?.name}</p>
                                                        </div>
                                                    </div>
                                                }
                                                {selectedDocument?.citationCount &&
                                                    <div className="w-full grid py-3 grid-cols-2">
                                                        <div>
                                                            <p className="text-gray-500 font-medium">{selectedLanguage === "es-ES" ? "Citas" : "Cites"}</p>
                                                        </div>
                                                        <div>
                                                            <p className='font-medium'> {selectedDocument?.citationCount} </p>
                                                        </div>
                                                    </div>
                                                }
                                                {selectedDocument?.fieldsOfStudy &&
                                                    <div className="w-full grid py-3 grid-cols-">
                                                        <div>
                                                            <p className="text-gray-500 font-medium">{selectedLanguage === "es-ES" ? "Campos de estudio" : "Fields of study"} </p>
                                                        </div>
                                                        <div>
                                                            <p className='font-medium'> {selectedDocument?.fieldsOfStudy.join(", ")} </p>
                                                        </div>
                                                    </div>
                                                }
                                                {selectedDocument?.journal?.pages &&
                                                    <div className="w-full grid py-3 grid-cols-2 px-1">
                                                        <div>
                                                            <p className="text-gray-500 font-medium"> {selectedLanguage === "es-ES" ? "Páginas" : "Pages"} </p>
                                                        </div>
                                                        <div>
                                                            <p className='font-medium'>{selectedDocument?.journal?.pages}</p>
                                                        </div>
                                                    </div>
                                                }
                                                {selectedDocument?.journal?.volume &&
                                                    <div className="w-full grid py-3 grid-cols-2 px-1">
                                                        <div>
                                                            <p className="text-gray-500 font-medium"> {selectedLanguage === "es-ES" ? "Volúmen" : "Volume"} </p>
                                                        </div>
                                                        <div>
                                                            <p className='font-medium'>{selectedDocument?.journal?.volume}</p>
                                                        </div>
                                                    </div>
                                                }
                                                {selectedDocument?.referenceCount &&
                                                    <div className="w-full grid py-3 grid-cols-2 px-1">
                                                        <div>
                                                            <p className="text-gray-500 font-medium"> {selectedLanguage === "es-ES" ? "Referencias" : "Reference Count"} </p>
                                                        </div>
                                                        <div>
                                                            <p className='font-medium'>{selectedDocument?.referenceCount}</p>
                                                        </div>
                                                    </div>
                                                }
                                            </div>
                                        </div>
                                        <div className="w-full sm:border-l border-gray-300 sm:w-2/3 sm:p-6">
                                            <div className='flex items-center'>
                                                <a href={selectedDocument?.url} target="_blank">
                                                    <button className="flex items-center"> <HiMiniArrowTopRightOnSquare className="w-6 h-auto text-green-500 hover:text-green-700 cursor-pointer" /> {selectedLanguage === "es-ES" ? "Ir al documento" : "Go to source"} </button>
                                                </a>
                                            </div>
                                            <p className="text-gray-500 font-semibold pt-6 text-lg pb-2">Abstract</p>
                                            <p className="text-justify"> {selectedDocument?.translatedAbstract} </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <Footer />
                        </>
                    )}
                    {chatDoc && selectedPaper && (
                    <div className='w-full flex h-screen overflow-hidden'>
                        <div className='bg-white w-full flex flex-col h-screen overflow-hidden'>
                            <div className='p-4 border-b border-gray-200 flex items-center justify-between'>
                                <div className='flex items-center gap-4'>
                                    <button onClick={closeChat} className="p-2 hover:bg-gray-100 rounded-full transition-colors">
                                        <ArrowLeft className="w-5 h-5 text-gray-500" />
                                    </button>
                                    <a target='_blank' href={selectedPaper?.open_access?.url}
                                        className='text-blue-600 font-medium hover:underline max-w-xl truncate'>
                                        {selectedPaper?.title}
                                    </a>
                                </div>
                                <div className='flex items-center gap-3'>
                                    {getPDF && <Loader className='animate-spin w-5 h-5 text-gray-500' />}
                                    {ready && <img src={checkgreenOne} className='w-5 h-5' alt="Ready" />}
                                </div>
                            </div>

                            <div className='flex flex-1 overflow-hidden'>
                                <div className='w-full sm:w-1/2 flex flex-col border-r border-gray-200'>
                                    {displayError && (
                                        <div className='flex justify-center p-4'>
                                            <p className='text-sm text-gray-600'>
                                                {errorMessage ? errorMessage :
                                                    selectedLanguage === "es-ES"
                                                        ? "Ha occurido un error. Descargue el PDF manualmente"
                                                        : "An error has occurred. Download the PDF manually"
                                                }
                                            </p>
                                        </div>
                                    )}
                                        <div className='flex flex-col flex-grow overflow-hidden'>
                                            <div className='flex-grow overflow-y-auto'>
                                                <div className="flex flex-col gap-4 p-4">
                                                    {messages?.map((message, index) => (
                                                        <div key={index} className={`rounded-lg p-3 max-w-[85%] ${message.type === "user"
                                                                ? "ml-auto"
                                                                : "bg-gray-100"
                                                            }`}>
                                                            <p className='text-sm font-medium mb-1 text-gray-700'>
                                                                {message.type === "user" ? user?.displayName : ""}
                                                            </p>
                                                            <MessageContent text={message.text} />
                                                        </div>
                                                    ))}
                                                </div>
                                            </div>
                                        {!indexing && <SampleQuestions
                                            setQuestionx={setQuestionx}
                                            formRef={formRef}
                                            selectedLanguage={selectedLanguage}
                                            isVisible={isQuestionsVisible}
                                            setVisible={setIsQuestionsVisible}
                                        />}
                                        <div className='border-t border-gray-200 p-4'>
                                            {(getPDF || indexing) && (
                                                <div className="mb-2 text-sm text-gray-600">
                                                    Indexing paper, please wait...
                                                </div>
                                            )}
                                            <form
                                                ref={formRef}
                                                onSubmit={(e) => {
                                                    sendRequest(e, selectedPaper);
                                                    setIsQuestionsVisible(false);
                                                }}
                                                className="flex items-center gap-3 bg-gray-50 rounded-xl p-2 focus-within:ring-2 focus-within:ring-blue-100"
                                            >
                                                <input
                                                    disabled={getPDF || indexing}
                                                    placeholder={selectedLanguage === "es-ES" ? "Escribe tu mensaje..." : "Type your message..."}
                                                    value={questionx}
                                                    onChange={(e) => setQuestionx(e.target.value)}
                                                    className="flex-grow px-3 py-2 bg-transparent outline-none text-gray-700 placeholder-gray-500"
                                                />
                                                <button
                                                    type="submit"
                                                    disabled={getPDF || indexing} 
                                                    className="p-2 rounded-full hover:bg-white transition-colors"
                                                >
                                                    <Send className="w-5 h-5 text-blue-600" />
                                                </button>
                                            </form>
                                        </div>

                                        </div>
                                </div>

                                <div className='w-0 sm:w-1/2 bg-gray-50'>
                                    {selectedPaper?.open_access?.url ? (
                                        <iframe
                                            src={selectedPaper.open_access.url}
                                            className="w-full h-full"
                                            title="PDF Viewer"
                                        />
                                    ) : (
                                        <div className="flex items-center justify-center h-full text-gray-500">
                                            {selectedLanguage === "es-ES"
                                                ? "No hay PDF disponible"
                                                : "No PDF available"}
                                        </div>
                                    )}
                                </div>
                            </div>
                        </div>
                    </div>
                    )}  
                    {mainWebsite &&
                    <div className="flex flex-row h-screen bg-gray-50">
                        <aside className="w-0 sm:w-64 bg-white border-r border-gray-100 overflow-hidden flex flex-col fixed h-full hidden sm:flex">
                            <div className="flex justify-center py-6 border-b border-gray-100">
                                <div onClick={clearAll} className="flex cursor-pointer items-center space-x-3">
                                    <h1 className="text-xl font-bold text-gray-800">VortiX</h1>
                                    <div className="flex items-center bg-indigo-50 rounded-full px-3 py-1">
                                        <BeakerIcon className="w-4 h-4 text-indigo-600 mr-1" />
                                        <span className="text-xs font-medium text-indigo-600">Beta</span>
                                    </div>
                                </div>                            </div>

                            <div className="flex-1 overflow-hidden">
                                <List signIn={signIn} user={user} selectedLanguage={selectedLanguage} savedDocs={savedDocs} />
                            </div>

                            {user && (
                                <div className="fixed bottom-6 left-6 flex items-center space-x-2 bg-white px-3 py-2 rounded-full border border-gray-100 hover:border-gray-200 transition-colors">
                                    {user.photoURL ? (
                                        <img
                                            onClick={handleLogout}
                                            src={user.photoURL}
                                            className="w-8 h-8 rounded-full border border-gray-200 cursor-pointer"
                                            alt="User profile"
                                        />
                                    ) : (
                                        <UserCircleIcon onClick={handleLogout} className="w-8 h-8 text-gray-400 cursor-pointer" />
                                    )}
                                    <button onClick={handleLogout}
                                        className="text-sm flex items-center text-gray-600 hover:text-gray-900 transition-colors">
                                        <LogoutIcon className="w-4 h-4 text-red-400 mr-1.5" />
                                        Logout
                                    </button>
                                </div>
                            )}
                        </aside>
                        <div className="w-full flex justify-center h-full">
                            <div className="w-full sm:pl-64">
                                <div className="flex cursor-pointer sm:hidden pt-6 items-center justify-center">
                                    <h1 onClick={clearAll} className="text-xl font-bold text-gray-800">VortiX</h1>
                                    <div onClick={clearAll} className="flex items-center bg-indigo-50 rounded-full px-3 py-1">
                                        <BeakerIcon className="w-4 h-4 text-indigo-600 mr-1" />
                                        <span className="text-xs font-medium text-indigo-600">Beta</span>
                                    </div>
                                </div>
                                <div className="max-w-5xl mx-auto px-4 pt-8">
                                    <div className="flex flex-col md:flex-row items-center gap-4">
                                        <div className="w-full md:w-2/3">
                                            <form onSubmit={(e) => { e.preventDefault(); if (!loading) { handleSearch(); } }}
                                                className="flex items-center space-x-3 w-full bg-white border border-gray-200 rounded-2xl p-2 hover:border-gray-300 transition-colors">
                                                <input
                                                    placeholder="Ask a follow-up question..."
                                                    value={searchTerm}
                                                    onChange={(e) => setSearchTerm(e.target.value)}
                                                    className="flex-1 bg-transparent px-3 py-2 outline-none text-gray-700"
                                                    disabled={loading}
                                                />
                                                <div className="flex items-center space-x-2 pr-2">
                                                    <button type="button" onClick={clear}
                                                        className="text-gray-400 hover:text-gray-600 transition-colors">
                                                        <XIcon className="w-5 h-5" />
                                                    </button>
                                                    <button type="submit" disabled={loading}
                                                        className="text-indigo-500 hover:text-indigo-600 transition-colors">
                                                        <FaSearch className="w-5 h-5" />
                                                    </button>
                                                </div>
                                            </form>
                                            <div className="mt-3 flex items-center">
                                                <label className="relative inline-flex items-center cursor-pointer">
                                                    <input
                                                        type="checkbox"
                                                        className="sr-only peer"
                                                        checked={deepSearch}
                                                        onChange={(e) => setDeepSearch(e.target.checked)}
                                                    />
                                                    <div className="w-11 h-6 bg-gray-200 peer-focus:outline-none peer-focus:ring-4 peer-focus:ring-indigo-100 rounded-full peer peer-checked:after:translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:left-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all peer-checked:bg-indigo-600"></div>
                                                </label>
                                                <span className="ml-3 text-sm font-medium text-gray-700">
                                                    {selectedLanguage === "es-ES" ? "Búsqueda profunda" : "Deep Search"}
                                                </span>
                                                <span className="ml-1 group relative">
                                                    <SparklesIcon className="w-4 h-4 text-indigo-600 cursor-help" />
                                                    <span className="absolute bottom-full left-1/2 -translate-x-1/2 mb-2 w-48 p-2 bg-gray-800 text-white text-xs rounded-lg opacity-0 group-hover:opacity-100 transition-opacity">
                                                        {selectedLanguage === "es-ES"
                                                            ? "Búsqueda en más de 30 artículos académicos"
                                                            : "Search across 30+ academic papers"}
                                                    </span>
                                                </span>
                                            </div>
                                        </div>
                                        <div className="flex items-center justify-end space-x-6 w-full md:w-1/3">
                                            <Link to="/billing">
                                                <CreditCard
                                                    className="h-8 w-8 text-yellow-500 cursor-pointer"
                                                />
                                            </Link>
                                            <ListFilter
                                                onClick={() => setIsOpen(true)}
                                                className="h-8 w-8 text-indigo-600 cursor-pointer hover:text-indigo-500 transition-colors"
                                            />
                                            <PiBookmarksSimpleFill
                                                onClick={() => Navigate("/saved")}
                                                className="h-8 w-8 text-indigo-600 cursor-pointer hover:text-indigo-500 transition-colors"
                                            />
                                        </div>
                                    </div>
                                    <div className="mt-8">
                                        <div className="bg-white rounded-xl p-4 border border-gray-100">
                                            <div className="flex items-center mb-4">
                                                <SparkleIcon className={`w-5 h-5 text-indigo-500 ${loading ? 'animate-spin' : ''}`} />
                                                <span className="ml-2 font-medium text-gray-700">
                                                    {selectedLanguage === "es-ES" ? "Resúmen" : "Summary"}
                                                </span>
                                            </div>

                                            {loading ? (
                                                <div className="space-y-3 py-4">
                                                    <div className="h-3 bg-indigo-100 rounded-full w-full"></div>
                                                    <div className="h-3 bg-indigo-100 rounded-full w-5/6"></div>
                                                    <div className="h-3 bg-indigo-100 rounded-full w-4/6"></div>
                                                </div>
                                            ) : (
                                                <div className="prose max-w-none">
                                                    <p className="text-gray-600 text-justify whitespace-pre-wrap">
                                                        {formatTextWithCitations(summaryx)}
                                                    </p>
                                                </div>
                                            )}
                                        </div>
                                        {loading ? (
                                            <div className="space-y-4 mt-4">
                                                {[...Array(6)].map((_, index) => (
                                                    <div key={index} className="bg-white rounded-xl p-6 border border-gray-100">
                                                        <div className="animate-pulse space-y-4">
                                                            <div className="h-4 bg-indigo-100 rounded-full w-3/4"></div>
                                                            <div className="space-y-2">
                                                                <div className="h-3 bg-indigo-100 rounded-full w-full"></div>
                                                                <div className="h-3 bg-indigo-100 rounded-full w-5/6"></div>
                                                                <div className="h-3 bg-indigo-100 rounded-full w-4/6"></div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                ))}
                                            </div>
                                        ) : (
                                            <Result
                                                signInDos={signInDos}
                                                citationRefs={citationRefs}
                                                savedStates={savedStates}
                                                savedPapers={savedDocs}
                                                saved={saved}
                                                handleSaving={handleSaving}
                                                saveToFirebase={saveToFirebase}
                                                handlePaperSelection={handlePaperSelection}
                                                handleDocumentSelection={handleDocumentSelection}
                                                data={data}
                                                signIn={signIn}
                                                user={user}
                                                selectedLanguage={selectedLanguage}
                                                recommendations={recommendations}
                                            />
                                        )}
                                    </div>
                                </div>

                                {isOpen && (
                                    <Sidebar
                                        yearRange={yearRange}
                                        yearBefore={yearBefore}
                                        yearAfter={yearAfter}
                                        setYearRange={setYearRange}
                                        setYearBefore={setYearBefore}
                                        setYearAfter={setYearAfter}
                                        year={year}
                                        setYear={setYear}
                                        handleCheckboxChange={handleCheckboxChange}
                                        handleSearch={handleSearch}
                                        free={free}
                                        setFree={setFree}
                                        user={user}
                                        selectedLanguage={selectedLanguage}
                                        setIsOpen={setIsOpen}
                                        sidebarRef={sidebarRef}
                                        isOpen={isOpen}
                                    />
                                )}
                            </div>
                        </div>
                    </div>
                    }
                </>
            }
        </>
    );
};

export default Papers;
