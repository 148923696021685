import React, { useState } from "react";
import { db } from "../firebase"
import { getFirestore, collection, getDocs, addDoc, serverTimestamp } from 'firebase/firestore';

const UserInfoPopup = ({ onClose, signIn, onSubmit, user, selectedLanguage }) => {
    const [name, setName] = useState("");
    const [age, setAge] = useState("");
    const saveRef = collection(db, "custom_user");

    const saveToFirebase = async (e) => {
        e.preventDefault()
        if (!user || !user.email) {
            signIn();
            return;
        }
        try {
            await addDoc(saveRef, {
                uid: user.uid,
                name: name,
                age: age
            });
            alert(selectedLanguage==="es-ES"? "Guardado" : "Saved")
        } catch (error) {
            alert(selectedLanguage === "es-ES" ? "Error al guardar" : "Error saving");
        }
    };


    return (
        <div className="fixed inset-0 flex items-center justify-center z-50 bg-black bg-opacity-50">
            <div className="bg-white p-8 rounded-lg shadow-lg">
                <h2 className="text-xl mt-4 text-gray-600 mb-8 font-semibold"> {selectedLanguage === "es-ES" ? "Búsqueda web personalizada" : "Web search taylored to your needs"}</h2>
                <form onSubmit={saveToFirebase}>
                    <div className="mb-4">
                        <label htmlFor="name" className="block text-gray-600 font-semibold mb-1">
                            {selectedLanguage === "es-ES" ? "Nombre:" : "Name:"} 
                        </label>
                        <input
                            type="text"
                            id="name"
                            value={name}
                            onChange={(e) => setName(e.target.value)}
                            className="w-full outline-none px-2 py-2 border border-gray-300 rounded"
                            required
                        />
                    </div>
                    <div className="mb-4">
                        <label htmlFor="age" className="block text-gray-600 font-semibold mb-1">
                            {selectedLanguage === "es-ES" ? "Edad:" : "Age:"}
                        </label>
                        <input
                            type="number"
                            id="age"
                            value={age}
                            onChange={(e) => setAge(e.target.value)}
                            className="w-full outline-none px-2 py-2 border border-gray-300 rounded"
                            required
                        />
                    </div>
                    <div className="flex justify-center">
                        <button
                            type="submit"
                            onClick={(e) => saveToFirebase(e)}
                            className="px-4 py-2 font-semibold hover:bg-green-600 text-white bg-green-500 rounded"
                        >
                            {selectedLanguage === "es-ES" ? "Enviar" : "Submit"}
                        </button>
                    </div>
                    <div className="flex justify-end pt-8">
                        <button
                            type="button"
                            onClick={onClose}
                            className="px-4 py-2 mr-2 font-semibold text-red-500 hover:text-red-600 border-2 border-red-500 rounded"
                        >
                            {selectedLanguage === "es-ES" ? "Cerrar" : "Close"}
                        </button>
                    </div>

                </form>
            </div>
        </div>
    );
};

export default UserInfoPopup;