import React, {useState, useEffect} from "react"
import { FaChevronDown, FaSearch } from 'react-icons/fa';
import { IoArrowBackCircleOutline, IoSparklesSharp } from 'react-icons/io5';
import { XCircleIcon, UserCircleIcon, XIcon } from "@heroicons/react/solid";

const Sidebar = ({ sidebarRef, yearRange, yearBefore, yearAfter, setYearRange, setYearBefore, setYearAfter, handleCheckboxChange, selectedLanguage, free, year, setYear, handleSearch, setFree, isOpen, setIsOpen }) => {

    const [type, setType] = useState(false);
    const [field, setField] = useState(false);

    const handleCheckboxChangefree = (event) => {
        const isChecked = event.target.checked;
        setFree(isChecked ? "isOpenAccess" : "");
    };

    const toggleTypes = () => {
        setType(!type);
    };

    const toggleFields = () => {
        setField(!field);
    };

    const [years, setYears] = useState(false)

    const toggleYears = () => {
        setYears(!years)
    }

    const close = () => {
        setIsOpen(false);
    };

    return (
        <div
            ref={sidebarRef}
            className={`fixed inset-y-0 right-0 w-96 bg-white shadow-xl transform transition-transform duration-200 ease-in-out overflow-y-auto ${isOpen ? 'translate-x-0' : 'translate-x-full'}`}
        >
            <div className="sticky top-0 z-10 bg-white border-b border-gray-100 px-6 py-4">
                <div className='flex justify-between items-center'>
                    <p className="text-xl font-bold text-gray-800">
                        {selectedLanguage === "es-ES" ? "Filtros" : "Filters"}
                    </p>
                    <button onClick={close} className="text-gray-400 hover:text-gray-600 transition-colors">
                        <XIcon className='w-5 h-5' />
                    </button>
                </div>
            </div>
            <div className="px-6 py-4">
                <label className="flex items-center space-x-3 cursor-pointer">
                    <input
                        type='checkbox'
                        onChange={handleCheckboxChangefree}
                        checked={free === "isOpenAccess"}
                        className="w-4 h-4 text-indigo-600 border-gray-300 rounded focus:ring-indigo-500"
                    />
                    <span className="text-sm text-gray-600">{selectedLanguage === "es-ES" ? "Acceso gratis" : "Free access"}</span>
                </label>
            </div>
            <div
                onClick={toggleFields}
                className='flex items-center justify-between px-6 py-4 hover:bg-gray-50 cursor-pointer transition-colors border-t border-gray-100'
            >
                <div className='flex items-center space-x-2'>
                    <IoSparklesSharp className='w-5 h-5 text-indigo-600' />
                    <p className='font-medium text-gray-700'>
                        {selectedLanguage === "es-ES" ? "Áreas de estudio" : "Fields of study"}
                    </p>
                </div>
                <FaChevronDown className={`w-4 h-4 text-gray-400 transition-transform ${field ? 'rotate-180' : ''}`} />
            </div>

            {field && (
                <div className="py-2">
                    {[
                        { value: "Computer Science", label: selectedLanguage === "es-ES" ? "Ciencias de la Computación" : "Computer Science" },
                        { value: "Medicine", label: selectedLanguage === "es-ES" ? "Medicina" : "Medicine" },
                        { value: "Chemistry", label: selectedLanguage === "es-ES" ? "Química" : "Chemistry" },
                        { value: "Biology", label: selectedLanguage === "es-ES" ? "Biología" : "Biology" },
                        { value: "Materials Science", label: selectedLanguage === "es-ES" ? "Ciencia de los Materiales" : "Materials Science" },
                        { value: "Physics", label: selectedLanguage === "es-ES" ? "Física" : "Physics" },
                        { value: "Geology", label: selectedLanguage === "es-ES" ? "Geología" : "Geology" },
                        { value: "Psychology", label: selectedLanguage === "es-ES" ? "Psicología" : "Psychology" },
                        { value: "Art", label: selectedLanguage === "es-ES" ? "Arte" : "Art" },
                        { value: "History", label: selectedLanguage === "es-ES" ? "Historia" : "History" },
                        { value: "Geography", label: selectedLanguage === "es-ES" ? "Geografía" : "Geography" },
                        { value: "Sociology", label: selectedLanguage === "es-ES" ? "Sociología" : "Sociology" },
                        { value: "Business", label: selectedLanguage === "es-ES" ? "Empresas" : "Business" },
                        { value: "Political Science", label: selectedLanguage === "es-ES" ? "Ciencias Políticas" : "Political Science" },
                        { value: "Economics", label: selectedLanguage === "es-ES" ? "Economía" : "Economics" },
                        { value: "Philosophy", label: selectedLanguage === "es-ES" ? "Filosofía" : "Philosophy" },
                        { value: "Mathematics", label: selectedLanguage === "es-ES" ? "Matemáticas" : "Mathematics" },
                        { value: "Engineering", label: selectedLanguage === "es-ES" ? "Ingeniería" : "Engineering" },
                        { value: "Environmental Science", label: selectedLanguage === "es-ES" ? "Ciencias Ambientales" : "Environmental Science" },
                        { value: "Agricultural and Food Sciences", label: selectedLanguage === "es-ES" ? "Ciencias de la Agricultura y Alimentos" : "Agricultural and Food Sciences" },
                        { value: "Education", label: selectedLanguage === "es-ES" ? "Educación" : "Education" },
                        { value: "Law", label: selectedLanguage === "es-ES" ? "Leyes" : "Law" },
                        { value: "Linguistics", label: selectedLanguage === "es-ES" ? "Lingüística" : "Linguistics" }
                    ].map((item, index) => (
                        <div key={index} className="flex items-center px-6 py-2 hover:bg-gray-50">
                            <input
                                type="checkbox"
                                value={item.value}
                                onChange={(event) => handleCheckboxChange(event, 'field')}
                                className="w-4 h-4 text-indigo-600 border-gray-300 rounded focus:ring-indigo-500"
                            />
                            <label className="ml-3 text-sm text-gray-600">{item.label}</label>
                        </div>
                    ))}
                </div>
            )}
            <div
                onClick={toggleYears}
                className='flex items-center justify-between px-6 py-4 hover:bg-gray-50 cursor-pointer transition-colors border-t border-gray-100'
            >
                <div className='flex items-center space-x-2'>
                    <IoSparklesSharp className='w-5 h-5 text-indigo-600' />
                    <p className='font-medium text-gray-700'>
                        {selectedLanguage === "es-ES" ? "Años" : "Years"}
                    </p>
                </div>
                <FaChevronDown className={`w-4 h-4 text-gray-400 transition-transform ${years ? 'rotate-180' : ''}`} />
            </div>

            {years && (
                <div className="px-6 py-4 space-y-4">
                    <div className="space-y-2">
                        <label className="block text-sm font-medium text-gray-700">
                            {selectedLanguage === "es-ES" ? "Significa: Durante 2019" : "Meaning: During 2019"}
                        </label>
                        <input
                            value={year}
                            onChange={(e) => { setYear(e.target.value) }}
                            placeholder="2019"
                            className="w-full px-3 py-2 border border-gray-300 rounded-lg focus:ring-2 focus:ring-indigo-500 focus:border-indigo-500 text-gray-900 placeholder-gray-400"
                        />
                    </div>

                    <div className="space-y-2">
                        <label className="block text-sm font-medium text-gray-700">
                            {selectedLanguage === "es-ES" ? "Significa: Entre 2015 y 2020" : "Meaning: Between 2015 and 2020"}
                        </label>
                        <input
                            value={yearRange}
                            onChange={(e) => { setYearRange(e.target.value) }}
                            placeholder="2015-2020"
                            className="w-full px-3 py-2 border border-gray-300 rounded-lg focus:ring-2 focus:ring-indigo-500 focus:border-indigo-500 text-gray-900 placeholder-gray-400"
                        />
                    </div>

                    <div className="space-y-2">
                        <label className="block text-sm font-medium text-gray-700">
                            {selectedLanguage === "es-ES" ? "Significa: Durante o después del 2010" : "Meaning: During or after 2010"}
                        </label>
                        <input
                            value={yearAfter}
                            onChange={(e) => { setYearAfter(e.target.value) }}
                            placeholder="2010-"
                            className="w-full px-3 py-2 border border-gray-300 rounded-lg focus:ring-2 focus:ring-indigo-500 focus:border-indigo-500 text-gray-900 placeholder-gray-400"
                        />
                    </div>

                    <div className="space-y-2">
                        <label className="block text-sm font-medium text-gray-700">
                            {selectedLanguage === "es-ES" ? "Significa: Durante o antes del 2013" : "Meaning: During or before 2013"}
                        </label>
                        <input
                            value={yearBefore}
                            onChange={(e) => { setYearBefore(e.target.value) }}
                            placeholder="-2013"
                            className="w-full px-3 py-2 border border-gray-300 rounded-lg focus:ring-2 focus:ring-indigo-500 focus:border-indigo-500 text-gray-900 placeholder-gray-400"
                        />
                    </div>
                </div>
            )}
            <div className='sticky bottom-0 bg-white border-t border-gray-100 px-6 py-4 mt-4'>
                <button
                    onClick={handleSearch}
                    className='w-full px-4 py-2 bg-indigo-600 hover:bg-indigo-700 text-white font-medium rounded-lg transition-colors'
                >
                    {selectedLanguage === "es-ES" ? "Buscar" : "Search"}
                </button>
            </div>
        </div>
    );

};

export default Sidebar;