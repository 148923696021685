import { useState, useEffect } from "react"
import { Link } from "react-router-dom";
import { InformationCircleIcon } from "@heroicons/react/solid";
import { BeakerIcon, ChatAltIcon } from '@heroicons/react/solid';

const Header = ({ user, signIn }) => {


    const [selectedLanguage, setS] = useState("")

    useEffect(() => {
        const savedLanguage = localStorage.getItem('selectedLanguage');
        setS(savedLanguage)
    }, []);

    return (
        <div className="w-full flex">
            <Link to="/" className="flex items-center py-8 w-full md:w-1/3 xl:w-2/5 justify-center">
                <div className="flex items-center space-x-3">
                    <h1 className="text-xl font-bold text-gray-800">VortiX</h1>
                    <div className="flex items-center bg-indigo-50 rounded-full px-3 py-1">
                        <BeakerIcon className="w-4 h-4 text-indigo-600 mr-1" />
                        <span className="text-xs font-medium text-indigo-600">Beta</span>
                    </div>
                </div>
            </Link>
            <div className="md:w-2/3 xl:w-3/5 hidden sm:flex justify-center">
                <div className="flex my-8 sm:w-3/5 justify-evenly">
                </div>
            </div>
        </div>

    )
}

export default Header;