import React, {useState, useEffect} from "react"
import { FileText, MessageSquare, Bookmark, Quote, BookmarkCheck, ListFilter, ArrowLeft, SparkleIcon, Sparkles, Send, X, Loader } from 'lucide-react';
import { XCircleIcon, UserCircleIcon, XIcon } from "@heroicons/react/solid";
import { PiBookBookmarkBold, PiBookmarksSimpleFill, PiCheckCircleBold, PiCopyBold, PiQuotesBold, PiSparkleBold } from 'react-icons/pi';

const topJournals = [
    "Nature",
    "The New England Journal of Medicine",
    "Science",
    "Conference on Computer Vision and Pattern Recognition",
    "The Lancet",
    "Nature Communications",
    "Advanced Materials",
    "Cell",
    "Neural Information Processing Systems",
    "International Conference on Learning Representations",
    "JAMA",
    "Science of The Total Environment",
    "Nature Medicine",
    "Proceedings of the National Academy of Sciences",
    "Angewandte Chemie International Edition",
    "Chemical Reviews",
    "International Conference on Machine Learning",
    "Chemical Society Reviews",
    "Journal of Cleaner Production",
    "Nucleic Acids Research",
    "European Conference on Computer Vision",
    "Advanced Energy Materials",
    "Journal of the American Chemical Society",
    "IEEE Access",
    "Advanced Functional Materials",
    "Renewable and Sustainable Energy Reviews",
    "ACS Nano",
    "BMJ",
    "Physical Review Letters",
    "International Journal of Molecular Sciences",
    "Journal of Clinical Oncology",
    "AAAI Conference on Artificial Intelligence",
    "Science Advances",
    "PLoS ONE",
    "Frontiers in Immunology",
    "Scientific Reports",
    "Circulation",
    "Chemical Engineering Journal",
    "Energy & Environmental Science",
    "Applied Catalysis B: Environmental",
    "International Journal of Environmental Research and Public Health",
    "The Lancet Oncology",
    "Journal of the American College of Cardiology",
    "Meeting of the Association for Computational Linguistics",
    "Nutrients",
    "Nature Genetics",
    "Morbidity and Mortality Weekly Report",
    "Applied Energy",
    "Nature Biotechnology",
    "Sustainability",
    "Nano Energy",
    "Joule",
    "Journal of Materials Chemistry A",
    "Nature Materials",
    "IEEE Transactions on Pattern Analysis and Machine Intelligence",
    "ACS applied materials & interfaces",
    "Nature Energy",
    "ACS Catalysis",
    "The Lancet Infectious Diseases",
    "Conference on Empirical Methods in Natural Language Processing",
    "Journal of Business Research",
    "Gastroenterology",
    "European Heart Journal",
    "IEEE Internet of Things Journal",
    "Nature Nanotechnology",
    "Environmental Pollution",
    "The Astrophysical Journal",
    "Environmental Science & Technology",
    "Frontiers in Psychology",
    "Immunity",
    "Sensors",
    "Annals of Oncology",
    "ACS Energy Letters",
    "Journal of Hazardous Materials",
    "Communications Surveys & Tutorials",
    "Nature Neuroscience",
    "Gut",
    "Molecular Cancer",
    "Molecules",
    "Small",
    "Clinical Infectious Diseases",
    "Nature Methods",
    "Accounts of Chemical Research",
    "IEEE Transactions on Industrial Informatics",
    "Physical Review D",
    "Bioresource Technology",
    "American Economic Review",
    "Cell Metabolism",
    "Monthly Notices of the Royal Astronomical Society",
    "Chemosphere",
    "Blood",
    "Cell Reports",
    "Nano Letters",
    "Advanced Science",
    "Journal of High Energy Physics",
    "Nature Reviews Immunology",
    "Technological Forecasting and Social Change",
    "Frontiers in Microbiology",
    "Water Research",
]

const Result = ({ user, signInDos, savedStates, handleSaving, handlePaperSelection, recommendations, selectedLanguage }) => {

    const [expandedPapers, setExpandedPapers] = useState({});

    const toggleText = (index) => {
        setExpandedPapers(prevState => ({
            ...prevState,
            [index]: !prevState[index]
        }));
    };

    const [citationPopup, setCitationPopup] = useState({ show: false, citation: '' });
    const [showCopiedMessage, setShowCopiedMessage] = useState(false);

    const formatCitationAPA = (paper) => {
        const authors = `${paper.authors[0]} ${paper.authors.length > 1 ? "et al" : ""}`;
        const year = paper?.year || "";
        const title = paper?.title || "";
        const journal = paper?.journal?.name || "";
        const volume = paper?.volume || "";
        const pages = paper?.pages || "";
        const url = paper?.openAccessPdf?.url || paper?.url;
        return `${authors} (${year}). ${title}. <i>${journal}</i>, ${volume}, ${pages} <br/> <p style="color: #2563eb;">${url}</p>`;
    };

    const formatCitationMLA = (paper) => {
        const authors = `${paper.authors[0]} ${paper.authors.length > 1 ? "et al" : ""}`;
        const title = paper?.title || "";
        const journal = paper?.journal?.name || "";
        const volume = paper?.volume || "";
        const number = paper?.number || "";
        const year = paper?.year || "";
        const pages = paper?.pages || "";
        return `${authors}. "${title}." <i>${journal}</i>, vol. ${volume}, no. ${number}, ${year}, pp. ${pages}.`;
    };

    const formatCitationChicago = (paper) => {
        const authors = `${paper.authors[0]} ${paper.authors.length > 1 ? "et al" : ""}`
        const title = paper?.title || "";
        const journal = paper?.journal?.name || "";
        const volume = paper?.volume || "";
        const number = paper?.number || "";
        const year = paper?.year || "";
        const pages = paper?.pages || "";
        return `${authors}. "${title}." <i>${journal}</i> ${volume}, no. ${number} (${year}): ${pages}.`;
    };

    const formatCitationBibTeX = (paper) => {
        const authors = `${paper.authors[0]} ${paper.authors.length > 1 ? "et al" : ""}`
        const title = paper?.title || "";
        const journal = paper?.journal?.name || "";
        const year = paper?.year || "";
        const volume = paper?.volume || "";
        const number = paper?.number || "";
        const pages = paper?.pages || "";
        return `@article{${authors.replace(/\s/g, "")}_${year},\n  author = {${authors}},\n  title = {${title}},\n  journal = {${journal}},\n  year = {${year}},\n  volume = {${volume}},\n  number = {${number}},\n  pages = {${pages}}\n}`;
    };

    const showCitationPopup = (paper) => {
        const citationAPA = formatCitationAPA(paper);
        const citationMLA = formatCitationMLA(paper);
        const citationChicago = formatCitationChicago(paper);
        const citationBibTeX = formatCitationBibTeX(paper);
        setCitationPopup({
            show: true,
            citationAPA,
            citationMLA,
            citationChicago,
            citationBibTeX,
            selectedTab: "APA",
        });
    };

    const closeCitationPopup = () => {
        setCitationPopup({ show: false, citation: '' });
    };

    const copyToClipboard = (text) => {
        navigator.clipboard.writeText(text);
        setShowCopiedMessage(true);
        setTimeout(() => {
            setShowCopiedMessage(false);
        }, 1500);
    };

    return (
        <>
            {citationPopup.show && (
                <div className="fixed inset-0 flex items-center justify-center z-50">
                    <div className="modal-overlay absolute w-full h-full bg-gray-900 bg-opacity-50"></div>
                    <div className="modal-container bg-white w-11/12 md:max-w-md mx-auto shadow-xl z-50">
                        <div className="">
                            <div className="w-full flex bg-gray-100">
                                <div className="w-1/6"></div>
                                <div className="w-4/6 flex justify-center py-3">
                                    <p className="text-lg font-semibold text-gray-700">
                                        {selectedLanguage === "es-ES" ? "Citar fuente" : "Cite source"}
                                    </p>
                                </div>
                                <div className="w-1/6 flex items-center justify-center">
                                    <XCircleIcon
                                        onClick={closeCitationPopup}
                                        className="text-gray-700 h-6 w-6 hover:text-gray-800 cursor-pointer"
                                    />
                                </div>
                            </div>
                            <div className="grid grid-cols-4">
                                <div
                                    className={`py-2 px-4 flex justify-center cursor-pointer ${citationPopup.selectedTab === "APA"
                                        ? "bg-gray-200 text-gray-800"
                                        : "bg-gray-100 text-gray-600"
                                        }`}
                                    onClick={() =>
                                        setCitationPopup({ ...citationPopup, selectedTab: "APA" })
                                    }
                                >
                                    APA
                                </div>
                                <div
                                    className={`py-2 px-4 flex justify-center cursor-pointer ${citationPopup.selectedTab === "MLA"
                                        ? "bg-gray-200 text-gray-800"
                                        : "bg-gray-100 text-gray-600"
                                        }`}
                                    onClick={() =>
                                        setCitationPopup({ ...citationPopup, selectedTab: "MLA" })
                                    }
                                >
                                    MLA
                                </div>
                                <div
                                    className={`py-2 px-4 flex justify-center cursor-pointer ${citationPopup.selectedTab === "Chicago"
                                        ? "bg-gray-200 text-gray-800"
                                        : "bg-gray-100 text-gray-600"
                                        }`}
                                    onClick={() =>
                                        setCitationPopup({ ...citationPopup, selectedTab: "Chicago" })
                                    }
                                >
                                    Chicago
                                </div>
                                <div
                                    className={`py-2 px-4 flex justify-center cursor-pointer ${citationPopup.selectedTab === "BibTeX"
                                        ? "bg-gray-200 text-gray-800"
                                        : "bg-gray-100 text-gray-600"
                                        }`}
                                    onClick={() =>
                                        setCitationPopup({ ...citationPopup, selectedTab: "BibTeX" })
                                    }
                                >
                                    BibTeX
                                </div>
                            </div>
                            <div className="text-gray-600 rounded bg-gray-100 text-sm light p-6 relative">
                                <div
                                    className="citation-text font-sans break-words"
                                    dangerouslySetInnerHTML={{
                                        __html:
                                            citationPopup.selectedTab === "APA"
                                                ? citationPopup.citationAPA
                                                : citationPopup.selectedTab === "MLA"
                                                    ? citationPopup.citationMLA
                                                    : citationPopup.selectedTab === "Chicago"
                                                        ? citationPopup.citationChicago
                                                        : citationPopup.citationBibTeX,
                                    }}
                                ></div>
                                <PiCopyBold
                                    className="my-2 w-5 h-5 cursor-pointer hover:text-gray-800 text-gray-600"
                                    onClick={() =>
                                        copyToClipboard(
                                            citationPopup[`citation${citationPopup.selectedTab}`]
                                        )
                                    }
                                    title="Copy to clipboard"
                                />
                                {showCopiedMessage && (
                                    <div className="absolute bottom-2 border rounded-full left-1/2 transform -translate-x-1/2 bg-white border-green-500 text-green-500 px-5 py-2 rounded">
                                        {selectedLanguage === "es-ES" ? "Texto copiado" : "Text copied"}
                                    </div>
                                )}
                            </div>
                        </div>
                    </div>
                </div>
            )}
            {recommendations?.map((paper, index) => (
                <div key={index} className="flex justify-center">
                    <div className="w-11/12 sm:w-4/5 rounded-2xl bg-white my-3 shadow-lg hover:shadow-xl transition-shadow duration-200">
                        <div className="p-4">
                            <div className="flex flex-col w-full">
                                <a className="w-full py-2 group">
                                    <div className="flex items-start space-x-3 sm:space-x-4">
                                        <div className="flex-shrink-0">
                                            <span className="w-8 h-8 flex items-center justify-center rounded-full text-sm font-medium text-white bg-blue-600">
                                                {index + 1}
                                            </span>
                                        </div>
                                        <p
                                            onClick={() => paper?.url ? window.open(paper.url, '_blank', 'noopener,noreferrer') : null}
                                            className={`text-gray-700 text-sm sm:text-base ${paper?.url ? 'cursor-pointer group-hover:text-blue-600' : ''} line-clamp-3`}
                                        >
                                            {paper?.title}
                                        </p>
                                    </div>
                                </a>
                                {paper?.s2fieldsofstudy && paper.s2fieldsofstudy.length > 0 && (
                                    <div className="flex flex-wrap gap-2 mt-2 ml-10">
                                        {paper.s2fieldsofstudy.map((field, idx) => (
                                            <span
                                                key={idx}
                                                className="px-2 py-1 text-xs rounded-full bg-blue-50 text-blue-700 font-medium"
                                            >
                                                {field.category}
                                            </span>
                                        ))}
                                    </div>
                                )}
                            </div>
                            {paper?.summary && (
                                <div className="flex w-full justify-center mt-3">
                                    <div className="w-full sm:w-11/12 rounded-xl p-3 bg-gray-50">
                                        <p
                                            style={expandedPapers[index] ? {} : {
                                                display: '-webkit-box',
                                                WebkitBoxOrient: 'vertical',
                                                WebkitLineClamp: '3',
                                                overflow: 'hidden'
                                            }}
                                            className="text-gray-700 text-sm leading-relaxed"
                                        >
                                            {paper?.summary}
                                        </p>
                                        <button
                                            onClick={() => toggleText(index)}
                                            className="mt-2 text-blue-600 text-sm font-medium hover:text-blue-700"
                                        >
                                            {expandedPapers[index] ?
                                                selectedLanguage === "es-ES" ? "Ver menos" : "See less" :
                                                selectedLanguage === "es-ES" ? "Ver más" : "See more"
                                            }
                                        </button>
                                    </div>
                                </div>
                            )}
                            <div className="flex justify-center pt-3">
                                <div className="w-full sm:w-11/12 sm:flex">
                                    <div className="w-full sm:w-1/2 flex items-center">
                                        <span className="text-gray-600 font-medium text-sm flex items-center">
                                            {paper?.journal?.name}
                                            {topJournals.includes(paper?.journal?.name) && (
                                                <span className="flex items-center ml-2">
                                                    <PiSparkleBold className="w-5 h-5 text-yellow-500" />
                                                    <span className="ml-1 text-gray-700">
                                                        {selectedLanguage === "es-ES" ? "Revista influyente" : "Influential Journal"}
                                                    </span>
                                                </span>
                                            )}
                                        </span>
                                    </div>
                                    <div className="flex w-full sm:w-1/2 mt-2 sm:mt-0">
                                        {paper?.authors && paper.authors.length > 0 && (
                                            <div className="w-1/2 flex">
                                                <p className="text-gray-600 text-sm italic">
                                                    {`${paper.authors[0]} ${paper.authors.length > 1 ? "et al" : ""}`}
                                                </p>
                                            </div>
                                        )}
                                        {(paper?.citations || paper?.year) && (
                                            <>
                                                <div className="w-1/3 flex justify-center">
                                                    {paper?.citations && (
                                                        <p className="text-gray-600 text-sm">
                                                            {typeof paper.citations === 'object' ? paper.citations.total : paper.citations} {selectedLanguage === "es-ES" ? "Citas" : "Citations"}
                                                        </p>
                                                    )}
                                                </div>
                                                <div className="w-1/6 flex justify-center">
                                                    <p className="text-gray-600 text-sm">{paper?.year}</p>
                                                </div>
                                            </>
                                        )}
                                    </div>
                                </div>
                            </div>

                            <div className="border-t border-gray-200 mt-4">
                                <div className="px-1 sm:px-6 py-3 flex justify-between">
                                    <div className="flex-1" />
                                    <div className="flex items-center space-x-4 sm:space-x-6">
                                        <button 
                                            className="flex items-center cursor-pointer group"
                                            onClick={() => user ? handleSaving(paper) : signInDos()}
                                        >
                                            {savedStates[paper.url] ? (
                                                <>
                                                    <BookmarkCheck className="w-5 h-5 text-green-500" />
                                                    <span className="hidden sm:block ml-2 text-sm font-medium text-gray-600 group-hover:text-blue-600">
                                                        {selectedLanguage === "es-ES" ? "Guardado" : "Saved"}
                                                    </span>
                                                </>
                                            ) : (
                                                <>
                                                    <Bookmark className="w-5 h-5 text-blue-500" />
                                                    <span className="hidden sm:block ml-2 text-sm font-medium text-gray-600 group-hover:text-blue-600">
                                                        {selectedLanguage === "es-ES" ? "Guardar" : "Save"}
                                                    </span>
                                                </>
                                            )}
                                        </button>
                                        <button
                                            className="flex items-center cursor-pointer group"
                                            onClick={() => showCitationPopup(paper)}
                                        >
                                            <Quote className="w-5 h-5 text-blue-500" />
                                            <span className="hidden sm:block ml-2 text-sm font-medium text-gray-600 group-hover:text-blue-600">
                                                {selectedLanguage === "es-ES" ? "Citar" : "Cite"}
                                            </span>
                                        </button>
                                        {paper?.open_access?.url && (
                                            <button
                                                className="flex items-center cursor-pointer group"
                                                onClick={() => window.open(paper.open_access.url, '_blank', 'noopener,noreferrer')}
                                            >
                                                <FileText className="w-5 h-5 text-blue-500" />
                                                <span className="hidden sm:block ml-2 text-sm font-medium text-gray-600 group-hover:text-blue-600">
                                                    {selectedLanguage === "es-ES" ? "PDF" : "PDF"}
                                                </span>
                                            </button>
                                        )}

                                        {paper?.open_access?.url && (
                                            <button
                                                onClick={(e) => handlePaperSelection(e, paper)}
                                                className="flex items-center cursor-pointer group"
                                            >
                                                <MessageSquare className="w-5 h-5 text-blue-500" />
                                                <span className="hidden sm:block ml-2 text-sm font-medium text-gray-600 group-hover:text-blue-600">
                                                    {selectedLanguage === "es-ES" ? "Chat" : "Chat"}
                                                </span>
                                            </button>
                                        )}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            ))}
        </>
    )
}

export default Result;