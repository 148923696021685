import React from "react";
import { FaSpinner, FaGoogle } from 'react-icons/fa';
import { BookmarkIcon, DocumentTextIcon, ExternalLinkIcon } from "@heroicons/react/solid";

const List = ({ selectedLanguage, savedDocs, user, signIn }) => {
    const sortedPapers = savedDocs?.sort((a, b) => b.timestamp - a.timestamp);

    return (
        <div className="flex flex-col h-full">
            {/* Header */}
            <div className="pt-5 pb-3 px-4">
                <div className="flex items-center justify-between border-b border-gray-200 pb-3">
                    <div className="flex items-center space-x-2">
                        <BookmarkIcon className="w-5 h-5 text-indigo-600" />
                        <p className="font-medium text-gray-900">
                            {selectedLanguage === "es-ES" ? "Papers guardados" : "Saved Papers"}
                        </p>
                    </div>
                    {user && (
                        <div className="flex items-center space-x-1.5 bg-gray-50 px-2.5 py-1 rounded-full">
                            <DocumentTextIcon className="w-4 h-4 text-gray-500" />
                            <span className="text-sm font-medium text-gray-600">
                                {savedDocs?.length ?? 0}
                            </span>
                        </div>
                    )}
                </div>
            </div>

            {/* Scrollable Papers List */}
            <div className="flex-1 overflow-y-auto px-4 mb-16">
                {user ? (
                    <>
                        {sortedPapers ? (
                            sortedPapers.length > 0 ? (
                                <div className="space-y-1">
                                    {sortedPapers.map((paper, index) => (
                                        <div
                                            key={index}
                                            className="group relative p-3 hover:bg-gray-50 rounded-lg transition-colors border border-transparent hover:border-gray-100"
                                        >
                                            <a
                                                href={paper?.url}
                                                target="_blank"
                                                rel="noopener noreferrer"
                                                className="flex items-start space-x-3"
                                            >
                                                <DocumentTextIcon className="w-4 h-4 mt-0.5 text-gray-400 group-hover:text-gray-500" />
                                                <span className="flex-1 text-sm text-gray-600 group-hover:text-gray-900 line-clamp-2">
                                                    {paper?.title}
                                                </span>
                                                <ExternalLinkIcon className="w-4 h-4 text-gray-400 opacity-0 group-hover:opacity-100 transition-opacity" />
                                            </a>
                                        </div>
                                    ))}
                                </div>
                            ) : (
                                <div className="flex flex-col items-center py-12">
                                    <DocumentTextIcon className="w-12 h-12 text-gray-300 mb-3" />
                                    <p className="text-sm text-gray-500">
                                        {selectedLanguage === "es-ES"
                                            ? "No tiene papers guardados."
                                            : "You have no saved papers."}
                                    </p>
                                </div>
                            )
                        ) : (
                            <div className="flex justify-center py-12">
                                <FaSpinner className="animate-spin w-6 h-6 text-indigo-500" />
                            </div>
                        )}
                    </>
                ) : (
                    <div className="flex flex-col items-center py-12">
                        <DocumentTextIcon className="w-12 h-12 text-gray-300 mb-4" />
                        <p className="text-sm text-gray-600 mb-4 max-w-[200px] text-center">
                            {selectedLanguage === "es-ES"
                                ? "Inicie sesión para ver sus papers guardados"
                                : "Sign in to see your saved papers"}
                        </p>
                        <button
                            onClick={signIn}
                            className="flex items-center bg-white text-gray-700 border border-gray-200 px-4 py-2 rounded-lg hover:bg-gray-50 hover:border-gray-300 transition-all"
                        >
                            <FaGoogle className="w-4 h-4 mr-2 text-red-500" />
                            <span className="text-sm">
                                {selectedLanguage === "es-ES" ? "Iniciar con Google" : "Sign in with Google"}
                            </span>
                        </button>
                    </div>
                )}
            </div>
        </div>
    );
};

export default List;