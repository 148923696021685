import React, { useState, useEffect } from 'react';

const AuthPopup = ({ isOpen, onClose, onGoogleSignIn, onEmailSignIn, selectedLanguage }) => {
    const [isSignUp, setIsSignUp] = useState(false);
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [error, setError] = useState('');

    useEffect(() => {
        if (isOpen) {
            document.body.style.overflow = 'hidden';
        } else {
            document.body.style.overflow = 'unset';
        }
        return () => {
            document.body.style.overflow = 'unset';
        };
    }, [isOpen]);

    const handleSubmit = async (e) => {
        e.preventDefault();
        setError('');
        try {
            await onEmailSignIn(email, password, isSignUp);
            onClose();
        } catch (err) {
            setError(err.message);
        }
    };

    if (!isOpen) return null;

    return (
        <div className="fixed inset-0 z-50 overflow-y-auto">
            <div className="fixed inset-0 bg-black bg-opacity-30 backdrop-blur-sm" onClick={onClose}></div>

            <div className="relative min-h-screen flex items-center justify-center p-4">
                <div className="relative bg-white w-full max-w-md rounded-2xl shadow-2xl p-8">
                    <button
                        onClick={onClose}
                        className="absolute top-4 right-4 text-gray-400 hover:text-gray-500"
                    >
                        <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5" viewBox="0 0 20 20" fill="currentColor">
                            <path fillRule="evenodd" d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z" clipRule="evenodd" />
                        </svg>
                    </button>

                    <div className="text-center mb-8">
                        <h2 className="text-2xl font-bold text-gray-900">
                            {isSignUp
                                ? (selectedLanguage === "es-ES" ? "Crear cuenta" : "Create account")
                                : (selectedLanguage === "es-ES" ? "Iniciar sesión" : "Sign in")}
                        </h2>
                    </div>

                    <button
                        onClick={onGoogleSignIn}
                        className="w-full flex items-center justify-center gap-3 bg-white border border-gray-300 rounded-xl p-4 text-gray-700 font-medium hover:bg-gray-50 transition-colors mb-6"
                    >
                        <svg className="h-5 w-5" viewBox="0 0 24 24">
                            <path
                                d="M22.56 12.25c0-.78-.07-1.53-.2-2.25H12v4.26h5.92c-.26 1.37-1.04 2.53-2.21 3.31v2.77h3.57c2.08-1.92 3.28-4.74 3.28-8.09z"
                                fill="#4285F4"
                            />
                            <path
                                d="M12 23c2.97 0 5.46-.98 7.28-2.66l-3.57-2.77c-.98.66-2.23 1.06-3.71 1.06-2.86 0-5.29-1.93-6.16-4.53H2.18v2.84C3.99 20.53 7.7 23 12 23z"
                                fill="#34A853"
                            />
                            <path
                                d="M5.84 14.09c-.22-.66-.35-1.36-.35-2.09s.13-1.43.35-2.09V7.07H2.18C1.43 8.55 1 10.22 1 12s.43 3.45 1.18 4.93l2.85-2.22.81-.62z"
                                fill="#FBBC05"
                            />
                            <path
                                d="M12 5.38c1.62 0 3.06.56 4.21 1.64l3.15-3.15C17.45 2.09 14.97 1 12 1 7.7 1 3.99 3.47 2.18 7.07l3.66 2.84c.87-2.6 3.3-4.53 6.16-4.53z"
                                fill="#EA4335"
                            />
                        </svg>
                        {selectedLanguage === "es-ES"
                            ? "Continuar con Google"
                            : "Continue with Google"}
                    </button>

                    <div className="relative mb-6">
                        <div className="absolute inset-0 flex items-center">
                            <div className="w-full border-t border-gray-200" />
                        </div>
                        <div className="relative flex justify-center text-sm">
                            <span className="bg-white px-4 text-gray-500">
                                {selectedLanguage === "es-ES" ? "o" : "or"}
                            </span>
                        </div>
                    </div>

                    <form onSubmit={handleSubmit} className="space-y-4">
                        <div>
                            <input
                                type="email"
                                value={email}
                                onChange={(e) => setEmail(e.target.value)}
                                placeholder={selectedLanguage === "es-ES" ? "Correo electrónico" : "Email"}
                                className="w-full px-4 py-3 rounded-xl border border-gray-200 focus:border-indigo-500 focus:ring-2 focus:ring-indigo-200 transition-colors"
                                required
                            />
                        </div>
                        <div>
                            <input
                                type="password"
                                value={password}
                                onChange={(e) => setPassword(e.target.value)}
                                placeholder={selectedLanguage === "es-ES" ? "Contraseña" : "Password"}
                                className="w-full px-4 py-3 rounded-xl border border-gray-200 focus:border-indigo-500 focus:ring-2 focus:ring-indigo-200 transition-colors"
                                required
                            />
                        </div>

                        {error && (
                            <p className="text-sm text-red-600 mt-2">{error}</p>
                        )}

                        <button
                            type="submit"
                            className="w-full bg-indigo-600 text-white rounded-xl py-3 font-medium hover:bg-indigo-700 transition-colors"
                        >
                            {isSignUp
                                ? (selectedLanguage === "es-ES" ? "Crear cuenta" : "Sign up")
                                : (selectedLanguage === "es-ES" ? "Iniciar sesión" : "Sign in")}
                        </button>
                    </form>

                    <p className="mt-6 text-center text-sm text-gray-500">
                        {isSignUp
                            ? (selectedLanguage === "es-ES"
                                ? "¿Ya tienes una cuenta? "
                                : "Already have an account? ")
                            : (selectedLanguage === "es-ES"
                                ? "¿No tienes una cuenta? "
                                : "Don't have an account? ")}
                        <button
                            onClick={() => setIsSignUp(!isSignUp)}
                            className="text-indigo-600 font-medium hover:text-indigo-500"
                        >
                            {isSignUp
                                ? (selectedLanguage === "es-ES" ? "Inicia sesión" : "Sign in")
                                : (selectedLanguage === "es-ES" ? "Regístrate" : "Sign up")}
                        </button>
                    </p>
                </div>
            </div>
        </div>
    );
};

export default AuthPopup;